<template>
	<div>
		<v-app-bar fixed dark color="background" class="ios-barx bg-fade py-lg-2 pr-lg-3" elevation="0" clipped-left height="56px">

			<router-link class="d-flex align-center" :to="{name: 'Home'}">
				<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../assets/logo_small.png" transition="scale-transition" width="40"/>
				<span class="text-h5">{{ title }}</span>
			</router-link>

			<v-spacer></v-spacer>

			<v-btn icon class="mr-4 " @click="filterDrawer = !filterDrawer">
				<v-icon>mdi-filter</v-icon>
			</v-btn>

			<v-btn v-if="!hideNewButton && user.subscription_status !== 'Lapsed'" rounded color="accent" class="d-none d-sm-flex mr-5" :to="{name: 'NewMemory'}">
				<v-icon>mdi-plus</v-icon>
				<span>add memory</span>
			</v-btn>
			<v-badge v-if="notifications.length > 0" bordered color="accent" :content="notificationsBadge" offset-x="50" offset-y="24" overlap>
				<v-btn icon @click="notificationsDrawer = true">
					<v-icon>mdi-bell</v-icon>
				</v-btn>
			</v-badge>

			<v-btn v-else icon @click="notificationsDrawer = true">
				<v-icon>mdi-bell</v-icon>
			</v-btn>

			<v-menu bottom right v-model="account_menu" min-width="225px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" class="ml-2">
						<v-avatar size="28" v-if="user.avatar" style="max-width:100%;" :class="avatarStatus">
							<v-img :src="user.avatar" :key="user.avatar"></v-img>
						</v-avatar>
						<v-icon v-else>mdi-account</v-icon>
					</v-btn>
				</template>

				<v-list color="primary">
					<r-switch-account @switched="account_menu = false"></r-switch-account>
					<v-list-item v-for="item in accountMenuItems" :to="item.to" active-class="primary">
						<v-list-item-icon>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<r-notifications-drawer/>
	</div>
</template>
<script>

import {sync} from "vuex-pathify";

export default {
	name: 'r-app-bar',
	components: {},
	props: {
		hideNewButton: Boolean,
		hideActions: Boolean,
	},
	computed: {
		title: sync('app/title'),
		notifications: sync('app/notifications'),
		notificationsDrawer: sync('app/notificationsDrawer'),
		filterDrawer: sync('app/filterDrawer'),
		user: sync('user/user'),
		notificationsBadge() {
			return this.notifications.length > 9 ? '9+' : this.notifications.length
		},
		avatarStatus() {
			return this.user.subscription_status?.toLowerCase()
		}
	},
	data: () => ({
		account_menu: false,
		accountMenuItems: [
			{
				text: 'my account',
				icon: 'mdi-account',
				to: {name: 'Account'},
			},
			{
				text: 'onboard',
				icon: 'mdi-account-plus',
				to: {name: 'IntroWelcome'},
			},
			{
				text: 'logout',
				icon: 'mdi-logout',
				to: {name: 'Logout'},
			}
		],
	}),
}
</script>

<style lang="scss">
.v-toolbar {
	background-color: #0e0f1f!important;

	.v-toolbar__content {
		background-color: inherit;
		//margin-top: env(safe-area-inset-top)!important;
		//height: calc(56px + env(safe-area-inset-top));
	}
}
.trial {
	border: 2px solid #D9378B;
}

.active {
	border: 2px solid #6541c1;
}

.inactive {
	border: 2px solid #fff;
}

</style>
