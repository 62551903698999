<template>
	<v-expand-transition mode="out-in">
		<div>
			<v-overlay color="#171933" :value="appLoading">
				<div class="rewind_load">
					<div class="rewind_loader rewind_loader-1"></div>
					<div class="rewind_loader rewind_loader-2"></div>
					<div class="rewind_loader rewind_loader-3"></div>
				</div>
			</v-overlay>
			<router-view/>
		</div>
	</v-expand-transition>
</template>

<script>

import {Glassfy} from "capacitor-plugin-glassfy" ;
import {sync, get} from "vuex-pathify";
import {toast} from "@/utils/helpers";
import semver from "semver";
import {Capacitor} from "@capacitor/core";

let notificationInterval = null;
export default {
	name: 'App',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: '',
		// all titles will be injected into this template
		titleTemplate: '%s | Rewind'
	},
	computed: {
		topics: sync('app/topics'),
		notifications: sync('app/notifications'),
		user: sync('user/user'),
		userSignedIn: get('user/userSignedIn'),
		ping() {
			return !!this.$route.meta?.ping
		}
	},
	beforeMount() {
		if (this.$platform === 'ios') {
			this.checkPermissions()
		}
	},
	mounted() {
		this.init(true)
	},
	watch: {
		userSignedIn() {
			this.init()
		}
	},
	methods: {
		init(force) {
			this.getUser(force)
			this.initNotifications()
			this.getTopics()
			this.checkVersion()
		},
		async checkVersion() {
			if (this.$is_native) {
				const info = await this.$deviceApp.getInfo()
				this.$api.versions.update_available({platform: this.$platform, current_version: info.version})
					.then(response => {
						if (response.data?.update_available) {
							alert('Update available, please update rewind to get the latest features.')
						}
					})
			}
		},
		initNotifications() {
			if (this.userSignedIn && !this.notificationsInterval) {
				this.getNotifications()
				this.notificationsInterval = setInterval(() => this.getNotifications(), 60000 * 5)
			}
		},
		getUser(force) {
			if (this.userSignedIn && !this.user || this.userSignedIn && !!force) {
				this.$api.users.show()
					.then(response => {
						this.user = response.data;
						this.subscriptionCheckRequired();
						this.checkPermissions();
					})
					.catch(error => console.log(error))
			}
		},
		getTopics() {
			if (this.userSignedIn) {
				this.$api.topics.index()
					.then(response => this.topics = response.data)
					.catch(error => console.log(error))
			}
		},
		getNotifications() {
			if (this.userSignedIn) {
				this.$api.notifications.index()
					.then(response => this.notifications = response.data)
					.catch(error => console.log(error))
			}
		},
		subscriptionCheckRequired() {
			if (this.$platform === 'web' && this.user.iap_check_required) {
				this.$router.push({name: 'IAPCheck'})
			}
		},
		async checkPermissions() {
			if (this.$is_native && this.user.subscription_method === 'iap') {
				try {
					await Glassfy.initialize({apiKey: 'e04578662c1f48c5bbda93f0b240ec57', watcherMode: false});
					const permissions = await Glassfy.permissions();
					const permission = permissions.all.find((p) => p.permissionId === "premium");
					if (permission && permission.isValid) {
						this.$api.iap.update()
							.then(response => this.user = response.data)
							.catch(error => {
									console.log(error)
									toast.add('The was an error contact the store.', 'error')
								}
							)
					} else {
						this.$api.iap.delete()
							.then(response => this.user = response.data)
							.catch(error => {
									console.log(error)
									toast.add('The was an error, please try again.', 'error')
								}
							)
					}
				} catch (e) {
					console.log(e);
					toast.add('Error contacting store', 'error')
				}
			}
		}
	}
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@font-face {
	font-family: 'days_oneregular';
	src: url('./fonts/daysone-regular.woff2') format('woff2'),
	url('./fonts/daysone-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@import "./styles/app";
body {
	.v-app-bar {
		padding-top: env(safe-area-inset-top);
	}
}

.v-application {
	padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

	&:not(.no_top_bar) {
		padding-top: calc(env(safe-area-inset-top) + 56px) !important;
	}
}

.v-dialog--fullscreen, .v-navigation-drawer {
	top: env(safe-area-inset-top) !important;
}

html {
	background: #0e0f1f;
	overflow: auto !important;
}

a {
	text-decoration: none;
	color: inherit !important;
}

.hover_pointer:hover {
	cursor: pointer;
}

.v-card__actions > .v-btn.v-btn {
	padding-left: 18px !important;
	padding-right: 18px !important;
}

.rewind_load {
	display: flex;
	align-items: center;

	.rewind_loader {
		height: 50px;
		background: $accent;
		margin: 6px;
		width: 20px;
		border-radius: 100vmax;
		animation-name: load;
		animation-duration: 1200ms;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}

	.rewind_loader-3 {
		background: $primary;
	}

	.rewind_loader-1 {
		animation-delay: 0ms;
	}

	.rewind_loader-2 {
		animation-delay: 200ms;
	}

	.rewind_loader-3 {
		animation-delay: 400ms;
	}
}

@keyframes load {
	0% {
		height: 50px;
		background: $accent;
		margin: 6px;
		width: 20px;
	}
	50% {
		height: 100px;
		background: $primary;
		margin: 12px;
		width: 35px;
	}
	100% {
		height: 50px;
		background: $accent;
		margin: 6px;
		width: 20px;
	}
}

.v-label--active {
	transform: translateY(-22px) scale(.75) !important;
}

.v-slide-group__content {
	justify-content: center;
}

.v-list-item__title, .v-list-item__subtitle {
	overflow: visible !important;
	white-space: normal !important;
}

.no-active:before {
	opacity: 0 !important;
}

.attachment_card {
	background-color: transparent !important;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	box-shadow: none !important;

	.attachment_card_label {
		opacity: 0;
	}

	&:hover {
		.attachment_card_label {
			opacity: 1;
		}
	}
}

.v-card__title {
	word-break: break-word !important;
}

.avatar_active {
	border: 4px solid $accent;
}

.attachment_card {
	height: 300px;
}

@media (min-width: 400px) {
	.attachment_card {
		height: 350px;
	}
}

@media (min-width: 600px) {
	.attachment_card {
		height: 260px;
	}
}

@media (min-width: 990px) {
	.attachment_card {
		height: 300px;
	}
}

@media (min-width: 1220px) {
	.attachment_card {
		height: 300px;
	}
}

</style>
