<template>
	<v-btn @click="selectImage" rounded large color="accent">
		<v-icon left>mdi-camera</v-icon>
		camera
	</v-btn>
</template>

<script>

// Image upload instructions
// https://devdactic.com/ionic-image-upload-capacitor/

import {Directory, Filesystem} from "@capacitor/filesystem";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
	const reader = new FileReader;
	reader.onerror = reject;
	reader.onload = () => {
		resolve(reader.result);
	};
	reader.readAsDataURL(blob);
});

export default {
	name: "RCameraUpload2",
	props: ['value'],
	data: () => ({
		images: []
	}),
	watch: {
		images() {
			this.handleChange()
		}
	},
	methods: {
		async selectImage() {
			const image = await Camera.getPhoto({
				quality: 90,
				allowEditing: false,
				resultType: CameraResultType.Base64,
				source: CameraSource.Camera // Camera, Photos or Prompt!
			});

			if (image) {
				let blob = this.b64toBlob(image.base64String)
				this.images.push({
					base64: image,
					blob: blob
				})
			}
		},
		handleChange() {
			this.$emit('input', this.images)
		},
	}
}
</script>