import axios from 'axios'
import store from '../store/index'

let env = 'Live'

let baseURL;

switch (env) {
	case 'Local':
		baseURL = 'http://localhost:3000';
		// baseURL = 'http://10.0.2.2:3000';
		break;
	case 'Live':
		baseURL = 'https://api2.rewind-remind.me';
		break;
	default:
		baseURL = 'http://localhost:3000';
	// baseURL = 'http://10.0.2.2:3000';
}

axios.defaults.baseURL = `${baseURL}/`;

axios.defaults.headers = {
	'Content-Type': 'application/json',
	'Accept': 'application/json',
	'X-Api-Key': 'PGwhbR73TVVkNbVmX3FG9ZZWxHqTW5Vw',
	"Access-Control-Allow-Origin":"*"
};
axios.interceptors.request.use((config) => {
	// store.dispatch('app/addLoading', null, {root: true})
	let token = store.getters['user/token']
	config.headers = {...config.headers, 'Authorization': token}
	return config;
}, function (error) {
	return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
	// store.dispatch('app/reduceLoading', null, {root: true})
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// store.dispatch('app/reduceLoading', null, {root: true})
	// Do something with response error
	return Promise.reject(error);
});

export default {
	baseURL: baseURL,
	versions: {
		update_available: (data) => (axios.post('versions/update_available', data)),
	},
	sessions: {
		create: (payload) => (axios.post('sessions', payload))
	},
	users: {
		create: (user) => (axios.post('users', user)),
		update: (user) => (axios.put(`users/1`, user, {headers: {'Content-Type': 'multipart/form-data'}})),
		destroy: (credentials) => (axios.post(`users/1/destroy`, {credentials})),
		show: () => (axios.get(`users/1`)),
	},
	reset_password: {
		create: (data) => (axios.post(`reset_password/create`, data)),
		update: (data) => (axios.post(`reset_password/update`, data)),
	},
	memories: {
		year: (year) => (axios.get(`memories/year/${year}`)),
		share: (id, share_list) => (axios.post(`memories/${id}/share`, share_list)),
		index: () => (axios.get(`memories`)),
		create: (memory) => (axios.post('memories', memory)),
		update: (id, memory) => (axios.put(`memories/${id}`, memory)),
		destroy: (id) => (axios.delete(`memories/${id}`)),
		show: (id) => (axios.get(`memories/${id}`)),
		public_show: (share_token) => (axios.get(`memories/public/${share_token}`)),
		pin_to_year: (id) => (axios.post(`memories/${id}/pin_to_year`)),
		create_public_link: (id, expire_after) => (axios.post(`memories/${id}/create_public_link`, {expire_after})),
		delete_public_link: (id) => (axios.post(`memories/${id}/delete_public_link`)),
		delete_all_public_links: () => (axios.post(`memories/delete_all_public_links`)),
	},
	downloads: {
		attachment: (id) => (axios.get(`download/attachment/${id}`)),
	},
	topics: {
		index: () => (axios.get(`topics`)),
		show: (topic, year = null) => (axios.get(`topics/${topic}?year=${year}`)),
	},
	notifications: {
		index: () => (axios.get(`notifications`)),
	},
	shareRequest: {
		accept: (id) => (axios.get(`share_requests/${id}/accept`)),
		decline: (id) => (axios.get(`share_requests/${id}/decline`)),
	},
	friendRequest: {
		accept: (id) => (axios.get(`friend_requests/${id}/accept`)),
		decline: (id) => (axios.get(`friend_requests/${id}/decline`)),
	},
	search: {
		show: (search_term) => (axios.post(`search`, {search_term})),
	},
	shared_memories: {
		index: (friend_id) => (axios.get(`memories/shared/${friend_id}`)),
	},
	friends: {
		index: () => (axios.get(`friends`)),
	},
	stripe: {
		prices: () => (axios.get(`stripe/prices`)),
		checkout: (price_id) => (axios.post(`stripe/checkout/${price_id}`)),
		success: (session_id) => (axios.post(`stripe/success/${session_id}`)),
	},
	linked_account: {
		switch: (user_id) => (axios.get(`linked_accounts/switch/${user_id}`)),
		create: (user) => (axios.post('linked_accounts', user)),
	},
	subscriptions: {
		show: () => (axios.get(`subscriptions/1`)),
		cancel: () => (axios.delete(`subscriptions/1`)),
	},
	iap: {
		update: () => (axios.put(`iaps/1`)),
		delete: () => (axios.delete(`iaps/1`)),
	},
}