<template>
	<v-card class="attachment_card" :style="`background-image: url(${require('../assets/audio_icon.png')})`">
		<v-btn v-if="editable" small fab color="secondary" class="ma-3" @click="$emit('delete')">
			<v-icon>mdi-delete</v-icon>
		</v-btn>
		<div class="text-center" style="position: absolute; bottom: 0; left: 0; right: 0;">
			<div>
				<v-btn-toggle rounded>
					<v-btn icon class="primary-opacity-5" @click="audio.currentTime = 0">
						<v-icon>mdi-arrow-u-left-top</v-icon>
					</v-btn>
					<v-btn icon class="primary-opacity-5" @click="play">
						<v-icon>mdi-play</v-icon>
					</v-btn>
					<v-btn icon class="primary-opacity-5" @click="pause">
						<v-icon>mdi-pause</v-icon>
					</v-btn>
				</v-btn-toggle>
			</div>
		</div>



	</v-card>
</template>

<script>
export default {
	name: "RCardAudio",
	props: {
		url: String,
		editable: Boolean,
	},
	data() {
		return {
			audio: new Audio(this.url),
			playing: false
		}
	},
	computed: {
		isPlaying() {
			return this.audio.paused
		},
	},
	methods: {
		play(){
			this.audio.play()
		},
		pause(){
			this.audio.pause()
		},
		reset(){
			this.audio.currentTime = 0
		},
		play_pause() {
			if (this.audio.paused) {
				this.audio.play()
				this.playing = true
			} else {
				this.audio.pause()
				this.playing = false
			}
		}
	}
}
</script>