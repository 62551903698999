<template>
		<div v-if="toast" class="toast">
			<v-alert app :type="toast.type">
				<template #close>
					<v-icon class="ml-3" @click="removeAlert">mdi-close</v-icon>
				</template>
				<span>{{ toast.message }}</span>
			</v-alert>
		</div>
</template>

<script>
import {get} from 'vuex-pathify'

export default {
	name: "RToast",
	props: {
		type: {
			type: String,
			default: 'info'
		}
	},
	data: () => ({
		toastTimer: null,
		test: true
	}),
	watch: {
		toast() {
			if (this.toastTimer) return
			this.toastTimer = setTimeout(() => {
				this.removeAlertViaTimer()
			}, 5000)
		}
	},
	computed: {
		toast: get('app/toasts@0')
	},
	methods: {
		removeAlert() {
			this.$toast.remove()
		},
		removeAlertViaTimer() {
			this.removeAlert()
			clearTimeout(this.toastTimer)
			this.toastTimer = null
		}
	}
}
</script>

<style lang="scss">
.toast {
	//position fixed at bottom center of viewport
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);

	z-index: 300;
	text-align: center;

	& > .v-alert {
		display: inline-block;
	}
}
</style>