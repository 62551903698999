<template>
	<div>
		<v-btn v-if="float" class="ml-4 mb-4 float-btn d-lg-none" fab small dark color="accent" @click="filterDrawer = !filterDrawer">
			<v-icon dark>mdi-filter</v-icon>
		</v-btn>
		<v-btn v-else icon class="mr-4 d-none d-lg-block" @click="filterDrawer = !filterDrawer">
			<v-icon>mdi-filter</v-icon>
		</v-btn>
	</div>
</template>
<script>
import {sync} from "vuex-pathify";

export default {
	name: 'RFilterButton',
	props: {
		float: Boolean
	},
	computed: {
		filterDrawer: sync('app/filterDrawer'),
		isFloat() {
			return {'float-btn': this.float, 'header-btn': !this.float}
		}
	},
}
</script>

<style scoped>
.float-btn {
	position: fixed;
	bottom: 0;
	left: 0;
}

.header-btn {

}
</style>