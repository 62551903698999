<template>
	<v-card color="transparent" :to="to" exact>
		<v-img height="250" :src="imageSrc(thumbnail, true, icon)" :contain="!thumbnail">
			<v-list :class="connection ? 'accent-opacity-5' : 'primary-opacity-5'"  class="py-1" style="position: absolute; bottom: 0; left: 0; right:0;">
				<v-list-item color="transparent" class="py-0">
					<v-list-item-content class="transparent py-0">
						<v-list-item-title class="text-center" :class="titleClass">
							{{ title || 'untitled' }}
						</v-list-item-title>
						<v-list-item-subtitle v-if="subtitle" class="text-center">
							{{ subtitle }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-avatar size="40" v-if="curated" color="secondary" class="ma-1">
				<v-img src="../assets/logo_small.png"></v-img>
			</v-avatar>
			<v-icon v-else-if="shared" if="shared" color="accent" class="ma-1" large rounded>mdi-share</v-icon>
		</v-img>
	</v-card>
</template>

<script>
export default {
	name: "RMemoryTile",
	props: {
		to: {
			type: Object,
			required: true
		},
		title: {
			required: true,
		},
		subtitle: {
			required: false,
		},
		thumbnail: {
			type: String
		},
		icon: {
			type: String,
			default: null
		},
		titleClass: String,
		shared: Boolean,
		curated: Boolean,
		connection: Boolean
	}
}
</script>

<style scoped>

</style>