<template>
	<v-card class="background pt-15" elevation="0">
		<v-row justify="center">
			<v-col cols="auto">
				<v-img src="../assets/nomemory.png" max-width="400"></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="auto">
				<v-btn color="accent" x-large rounded :to="{name: 'NewMemory', params: {year: $route.params.year}}">create a memory</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: "RNoMemory",
	props: {
		message: {
			type: String,
			default: 'create a new memory'
		}

	}
}
</script>