import store from '../store/index'
import _forEach from "lodash/forEach";
import vue from 'vue'
import {sync} from "vuex-pathify";

export function leadingSlash(str) {
	return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash(str) {
	return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
	return new Promise(resolve => setTimeout(resolve, timeout))
}

export const loading = {
	get: function () {
		return store.getters['app/loading']
	},
	set: function (value = false) {
		store.commit('app/loading', value)
	}
}

export const toast = {
	add: (message, type = 'info') => store.commit('app/addToast', {message, type}),
	remove: () => store.commit('app/removeToast'),
	handleResponseErrors: (errors = []) => {
		errors = errors.response.data.errors || errors.response.data.error
		Array.isArray(errors) ?
			errors.forEach((error) => toast.add(`${error.detail}`, 'error')) :
			toast.add(`${errors.detail}`, 'error')
	}

}

export const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
	const reader = new FileReader;
	reader.onerror = reject;
	reader.onload = () => {
		resolve(reader.result);
	};
	reader.readAsDataURL(blob);
});

export const b64toBlob = (b64Data, contentType='image/jpeg', sliceSize=512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, {type: contentType});

	return blob;
}