<template>
	<v-dialog v-model="dialog" max-width="290" v-if="user.linked_accounts.length > 0">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-on="on">
				<v-list-item-icon>
					<v-icon>mdi-swap-horizontal</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>switch account</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</template>

		<v-card color="background">
			<v-card-title class="text-h5">
				switch account
			</v-card-title>

			<v-card-text>
				<v-list color="background">
					<v-list-item v-for="account in user.linked_accounts" active-class="primary" class="hover_pointer" @click="switchAccount(account)">
						<v-list-item-avatar class="active">
							<v-img :src="imageSrc(account.avatar, true)" :class="{'avatar_active': account.id === user.id}"></v-img>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title v-text="account.name"></v-list-item-title>
							<v-list-item-title v-if="account.id === user.id" class="accent--text">logged in</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="cancel">cancel</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import {sync} from "vuex-pathify";
import {toast} from "@/utils/helpers";

export default {
	name: "RSwitchAccount",
	computed: {
		user: sync('user/user'),
	},
	data: () => ({
		dialog: false
	}),
	methods: {
		switchAccount(account) {
			this.$api.linked_account.switch(account.id)
				.then(response => {
					this.$store.set('user/user', response.data.user)
					this.$store.set('user/token', response.data.token)
					this.$emit('switched')
					if(this.$route.name !== 'Home'){
						this.$router.push({name: 'Home'})
					}
					toast.add(`switched account to ${this.user.name}`, 'success')
				})
				.catch(error => console.log(error))
				.finally(() => this.dialog = false)
		},
		cancel(){
			this.dialog = false;
			this.$emit('switched')
		}
	}
}
</script>