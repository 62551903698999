// Icons
import icons from '@/utils/icons'

// Pathify
import {make} from 'vuex-pathify'

// Data
const state = {
	title:'rewind',
	topics: [],
	notifications: [],
	loading: 0,
	filterDrawer: false,
	notificationsDrawer: false,
	toasts: [],
	showShareHelp: true,
	grid_view: false,
}

const getters = {}

const mutations = {
	...make.mutations(state),
	addToast: (state, payload) => state.toasts.unshift(payload),
	removeToast: state => state.toasts.shift(),
}

const actions = {
	...make.actions(state),
	addLoading: ({ dispatch, state }) => dispatch('setLoading', state.loading + 1),
	reduceLoading: ({ dispatch, state }) => dispatch('setLoading', (state.loading - 1) < 0 ? 0 : state.loading - 1),
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
