import Vue from 'vue'
import {sync} from "vuex-pathify";

Vue.mixin({
	computed: {
		appLoading: sync('app/loading'),
		getMaxEndDate() {
			return new Date().toISOString()
		},
		datePickerWidth(){
			return this.$vuetify.breakpoint.xsOnly ? '230px' : '300px'
		},
		menuNudgeLeft(){
			return this.$vuetify.breakpoint.xsOnly ? '20' : '0'
		}
	},
	filters: {
		format_number(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		format_currency(x, currency_code = 'GBP') {
			// Create our number formatter.
			const formatter = new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: currency_code,

				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});

			return formatter.format(x); /* $2,500.00 */
		}
	},
	methods: {
		goToComponent(ref) {
			let element = this.$refs[ref];
			let top = element.$el.offsetTop;

			window.scrollTo({
				top,
				behavior: 'smooth',
			})
		},
		getImagePreview(file) {
			if (file) {
				return URL.createObjectURL(file)
			} else {
				return ''
			}
		},
		imageSrc(src, random_toon = false, icon = null) {
			if (src) {
				return src
			}
			else if (icon){
				if(icon === 'text') return require(`@/assets/doc_icon.png`)
				if(icon === 'link') return require(`@/assets/url_icon.png`)
				if(icon === 'audio') return require(`@/assets/audio_icon.png`)
				if(icon === 'video') return require(`@/assets/video_icon.png`)
			}
			else {
				if (random_toon) {
					let x = this.randomNumber(1, 5)
					return require(`@/assets/toon_${x}.png`)
				} else {
					return require('@/assets/nomemory.png')
				}
			}
		},
		randomNumber(min = 1, max = 100) { // min and max included
			return Math.floor(Math.random() * (max - min + 1) + min)
		},
		b64toBlob(b64Data, contentType='image/jpeg', sliceSize=512) {
			const byteCharacters = atob(b64Data);
			const byteArrays = [];

			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const blob = new Blob(byteArrays, {type: contentType});

			return blob;
		},
	}
})