<template>

	<v-navigation-drawer app clipped color="background" class="pt-15" v-model="filterDrawer">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="text-h6">
					<v-form @submit.prevent="doSearch">
						<v-text-field placeholder="search" v-model="search"></v-text-field>
					</v-form>
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="text-h6">
					categories
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
		<v-divider></v-divider>
		<v-list nav>
			<v-list-item link :to="{name: 'Home'}" exact exact-active-class="no-active">
				<v-list-item-content>
					<v-list-item-title>all</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-for="(topic, i) in topics" link :to="filterByTopic(topic)">
				<v-list-item-content>
					<v-list-item-title>{{ topic.name }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
import {sync} from "vuex-pathify";

export default {
	name: 'RFilterDrawer',
	computed: {
		filterDrawer: sync('app/filterDrawer'),
		topics: sync('app/topics')
	},
	data: () => ({
		search: ''
	}),
	methods: {
		doSearch() {
			this.$router.push({name: 'SearchResults', params: {search_term: this.search}});
			this.search = null;
		},
		filterByTopic(topic) {
			let year = this.$route.params.year
			if (year) {
				return {name: 'Topic', params: {topic: topic.slug, year: year}}
			} else {
				return {name: 'Topic', params: {topic: topic.slug}}
			}
		}
	}
}
</script>
