<template>
	<v-card class="attachment_card" :style="`background-image: url(${require('../assets/doc_icon.png')})`" :href="url" target="_blank">
<!--		<v-img src="../assets/doc_icon.png">-->
<!--		-->
<!--		</v-img>-->
		<v-btn v-if="editable" small fab color="secondary" class="ma-3" @click="$emit('delete')">
			<v-icon>mdi-delete</v-icon>
		</v-btn>
		<div class="text-center primary attachment_card_label" style="position: absolute; bottom: 0; left: 0; right: 0;">
			<span class="">{{ filename }}</span>
		</div>
	</v-card>
</template>

<script>
export default {
	name: "RCardDoc",
	props: {
		url: String,
		filename: String,
		editable: Boolean,
	}
}
</script>