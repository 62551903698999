<template>
	<div>
		<v-btn v-if="!recording" @click="startRecording" fab large color="accent">
			<v-icon>mdi-microphone</v-icon>
		</v-btn>
		<v-btn v-else @click="stopRecording" fab large color="error">
			<v-icon>mdi-stop</v-icon>
		</v-btn>
	</div>
</template>

<script>
import {VoiceRecorder, VoiceRecorderPlugin, RecordingData, GenericResponse, CurrentRecordingStatus} from 'capacitor-voice-recorder';
import {Directory, Filesystem} from "@capacitor/filesystem";

export default {
	name: "RAudioRecorder",
	props: ['value'],
	watch: {
		files() {
			this.$emit('input', this.files)
		}
	},
	data: () => ({
	    recording: null,
		files: [],
	}),
	methods: {
		async startRecording(){
			if(this.recording) return;
			await VoiceRecorder.requestAudioRecordingPermission();
			this.recording = true;
			await VoiceRecorder.startRecording()
		},
		stopRecording(){
			if(!this.recording) return;
			VoiceRecorder.stopRecording()
			.then(async (result) => {
				this.recording = false;
				if(result.value && result.value.recordDataBase64){
					const recordData = result.value.recordDataBase64;
					const mimeType = result.value.mimeType;
					const blob = this.b64toBlob(recordData, mimeType)
					this.files.push(blob)
				}
				this.$toast.add('recording added', 'success')
			})
		}
	}
}
</script>