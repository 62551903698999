import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
	// icons: {
	// 	iconfont: 'mdi'
	// },
	theme: {
		dark: true,
		themes: {
			dark: {
				primary: '#6541c1',
				secondary: '#171933',
				light: '#1f1d2c',
				accent: '#D9378B',
				error: '#B71C1C',
				background: '#0e0f1f',
			},
		}
	},
});
