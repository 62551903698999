<template>
	<div class="d-inline">
		<v-btn @click="$refs.file_input.$refs.input.click()" large rounded color="accent">
			<v-icon left>{{ icon }}</v-icon>
			{{ label }}
		</v-btn>
		<v-file-input
			class="d-none"
			ref="file_input"
			@change="update"
			v-model="images"
			:value="value"
			@input="update"
			:multiple="multiple"
			:accept="accept"
		></v-file-input>
	</div>
</template>

<script>
export default {
	name: "RFileUpload",
	props: {
		value: {
			required: true
		},
		multiple: Boolean,
		label: {
			type: String,
			default: 'files'
		},
		icon: {
			type: String,
			default: 'mdi-folder'
		},
		accept :{
			type: String,
			default: "*"
		}
	},
	data: () => ({
		images: [],
	}),
	methods: {
		update() {
			if (this.multiple) {
				this.$emit('input', [...this.value, ...this.images])
			} else {
				this.$emit('input', this.images)
			}
		}
	}
}
</script>