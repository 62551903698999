<template>
	<v-overlay color="background" opacity="0.9" :value="value">
		<v-btn small style="position: fixed; right:0" fab color="accent" class="ma-5" @click="close">
			<v-icon>mdi-close</v-icon>
		</v-btn>
		<div class="lightbox">
			<div class="lightbox--inner" :style="`background-image: url(${url})`"></div>
		</div>
	</v-overlay>
</template>

<script>

export default {
	name: "RLightbox",
	props: ['value', 'url', 'download_url', 'type'],
	methods: {
		close() {
			this.$emit('input', false)
			console.log(this.value);
		}
	}
}
</script>

<style scoped lang="scss">
.download-btn {
	position: fixed;
	bottom: 0;
	left: 0;
	transform: translateX(calc(50vw - 60px))
}

.lightbox {
	height: 100vh;
	width: 100vw;

	.lightbox--inner {

		/* The image used */


		/* Full height */
		height: 100%;

		/* Center and scale the image nicely */
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}
</style>