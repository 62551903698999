import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import api from "./services/api";
import {Capacitor} from '@capacitor/core';
import './mixins'
import {toast} from "./utils/helpers";
import {App as deviceApp} from '@capacitor/app';
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.prototype.$platform = Capacitor.getPlatform();
Vue.prototype.$is_native = Capacitor.getPlatform() !== 'web'
Vue.prototype.$api = api
Vue.prototype.$deviceApp = deviceApp

Vue.prototype.$toast = toast

Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')


deviceApp.addListener('backButton', () => {
	router.go(-1)
});


// Handle share targets (when an app shares a file to rewind)
import {SendIntent} from "send-intent";

SendIntent.checkSendIntentReceived().then((result) => {
	handleIntent(result)
}).catch(err => console.error(err));

window.addEventListener("sendIntentReceived", () => {
	SendIntent.checkSendIntentReceived().then((result) => {
		handleIntent(result)
	});
})

function handleIntent(intent) {
	if (intent.url) {
		router.push({name: 'NewMemory', params: {intent: intent}})
	}
	// SendIntent.finish();
}