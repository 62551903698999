import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../store/index'
import { App, URLOpenListenerEvent } from '@capacitor/app';

import {
	layout,
	route,
} from '@/utils/routes'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (to.hash) return {selector: to.hash}
		if (savedPosition) return savedPosition

		return {x: 0, y: 0}
	},
	routes: [

		layout('Default', [
				route('Home', '/', 'Home', {pageTitle: 'Home'}),
				route('Year', '/year/:year', 'Year', {pageTitle: 'Year'}, true),
				route('Topic', '/category/:topic/:year?', 'Topic', {pageTitle: 'Category'}, true),
				route('SearchResults', '/search/:search_term', 'SearchResults', {pageTitle: 'Search'}, true),
				route('Shared', '/shared/:friend_id', 'Shared', {pageTitle: 'Shared'}, true),
			]
		),
		layout('Memory', [
				route('NewMemory', '/memory/new/:year?', 'memory/New', {pageTitle: 'New memory', edit: false}, true),
				route('EditMemory', '/memory/:id/edit', 'memory/Edit', {pageTitle: 'Edit memory', edit: true}, true),
				route('ShowMemory', '/memory/:id', 'memory/Show', {pageTitle: 'Memory'}, true),
			]
		),
		layout('Account', [
				route('Account', '/account', 'account/Show', {pageTitle: 'Account'}),
				route('NewLinkedAccount', '/linked_account/new', 'linked_account/New', {pageTitle: 'Linked Account'}),
				route('IAPCheck', '/account/iap_check', 'account/IapCheck', {pageTitle: 'IAP Check'}),
				route('Store', '/account/store', 'account/Store', {pageTitle: 'App Store'}),
				route('CloseAccount', '/account/close', 'account/Destroy', {pageTitle: 'Close Account'}),
				// route('AppStore', '/account/appstore', 'AppStore', {pageTitle: 'App Store'}),
				// route('Stripe', '/account/stripe', 'account/Stripe', {pageTitle: 'Stripe'}),
				route('StripeCheckout', '/stripe/checkout', 'stripe/Checkout', {pageTitle: 'Stripe Checkout'}),
				route('StripeSuccess', '/stripe/success', 'stripe/Success', {pageTitle: 'Stripe Success'}),
				route('StripeCancel', '/stripe/checkout', 'stripe/Checkout', {pageTitle: 'Stripe Checkout'}),

				route('ManageSubscription', '/account/manage_subscription', 'manage_subscription/Show', {pageTitle: 'Manage Subscription'}),
			]
		),
		layout('Onboarding', [
				route('IntroWelcome', '/intro/welcome', 'onboarding/Welcome', {pageTitle: 'Welcome', ping: false}),
				route('IntroTrial', '/intro/trial', 'onboarding/Trial', {pageTitle: 'Trial', ping: false}),
				route('IntroProfile', '/intro/profile', 'onboarding/Profile', {pageTitle: 'Profile', ping: false}),
				route('IntroCurated', '/intro/curated', 'onboarding/Curated', {pageTitle: 'Curated', ping: false}),
				route('IntroFinished', '/intro/finished', 'onboarding/Finished', {pageTitle: 'Finished', ping: false}),
				route('Splash', 'splash', 'Splash', {
					publicRoute: true,
				})
			]
		),
		layout('Share', [
			route('PublicMemoryShow', '/public/memory/:token', 'memory/Public', {
				pageTitle: 'Memory',
				publicRoute: true,
				ping: false
			}, true),
		]),
		layout('Auth', [
				route('Login', 'login', 'auth/Login', {
					pageTitle: 'Log in',
					publicRoute: true,
					ping: false
				}),
				route('SignUp', 'signup', 'auth/SignUp', {
					pageTitle: 'Sign up',
					publicRoute: true,
					ping: false
				}),
				route('Logout', 'logout', 'auth/Logout', {
					pageTitle: 'Log out',
					publicRoute: true,
					ping: false
				}),
				route('ForgotPassword', 'forgot_password', 'auth/ForgotPassword', {
					pageTitle: 'Forgot password',
					publicRoute: true,
					ping: false
				}),
				route('ResetPassword', 'reset_password/:token', 'auth/ResetPassword', {
					pageTitle: 'Reset password',
					publicRoute: true,
					ping: false
				}, true),

			]
		),
	]
})

router.beforeEach((to, from, next) => {
	let signedIn = Store.get('user/token')
	let requiresAuth = !to.meta.publicRoute
	if (!signedIn && requiresAuth) return next({name: 'Login'})
	return next()
})

// DEEP LINKS
App.addListener('appUrlOpen', function (event) {
	console.log('DEEP LINK: ', event);
	// Example url: https://beerswift.app/tabs/tabs2
	// slug = /tabs/tabs2
	const slug = event.url.split('.me').pop();

	// We only push to the route if there is a slug present
	if (slug) {
		return router.push({
			path: slug,
		});
	}
});


export default router
