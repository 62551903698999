<template>
	<v-btn @click="selectImage" rounded large class="mr-lg-3" color="accent">
		<v-icon left>mdi-camera</v-icon>
		camera
	</v-btn>
</template>

<script>

// Image upload instructions
// https://devdactic.com/ionic-image-upload-capacitor/

import {Directory, Filesystem} from "@capacitor/filesystem";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
	const reader = new FileReader;
	reader.onerror = reject;
	reader.onload = () => {
		resolve(reader.result);
	};
	reader.readAsDataURL(blob);
});

export default {
	name: "RCameraUpload",
	props: ['value'],
	mounted() {
		this.formData = new FormData();
		// this.loadFiles()
	},
	data: () => ({
		images: [],
		FILE_DIR: 'stored-images',
		localFile: {
			name: '',
			path: '',
			data: '',
		},
	}),
	watch: {
		images() {
			this.handleChange()
		}
	},
	methods: {

		handleChange() {
			this.$emit('input', this.images)
		},
		async loadFiles() {
			this.images = [];
			console.log('loading: on');
			Filesystem.readdir({
				path: this.FILE_DIR,
				directory: Directory.Data,
			}).then(result => {
					this.loadFileData(result.files);
				},
				async (err) => {
					// Folder does not yet exists!
					await Filesystem.mkdir({
						path: this.FILE_DIR,
						directory: Directory.Data,
					});
				}
			).then(() => {
				console.log('loading: off');
			});
		},

		// Get the actual base64 data of an image
		// base on the name of the file
		async loadFileData(fileNames) {
			for (let f of fileNames) {
				const filePath = `${this.FILE_DIR}/${f}`;

				const readFile = await Filesystem.readFile({
					path: filePath,
					directory: Directory.Data,
				});

				this.images.push({
					name: f,
					path: filePath,
					data: `data:image/jpeg;base64,${readFile.data}`,
				});
			}
		},

		async selectImage() {
			const image = await Camera.getPhoto({
				quality: 90,
				allowEditing: false,
				resultType: CameraResultType.Uri,
				source: CameraSource.Camera // Camera, Photos or Prompt!
			});

			if (image) {
				await this.saveImage(image)
			}
		},
		// Create a new file from a capture image
		async saveImage(photo) {
			const base64Data = await this.readAsBase64(photo);

			const fileName = new Date().getTime() + '.jpeg';
			const savedFile = await Filesystem.writeFile({
				path: `${this.FILE_DIR}/${fileName}`,
				data: base64Data,
				directory: Directory.Data
			});

			// Reload the file list
			// Improve by only loading for the new image and unshifting array!
			await this.loadFiles();
		},

		// https://ionicframework.com/docs/angular/your-first-app/3-saving-photos
		async readAsBase64(photo) {
			if (this.$is_native) {
				const file = await Filesystem.readFile({
					path: photo.path
				});
				return file.data;
			} else {
				// Fetch the photo, read as a blob, then convert to base64 format
				const response = await fetch(photo.webPath);
				const blob = await response.blob();
				console.log(blob);
				return await convertBlobToBase64(blob)
			}
		},
	}
}
</script>