<template>
	<v-navigation-drawer v-model="notificationsDrawer" temporary app right color="primary" width="425">
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="text-h6">
					notifications
				</v-list-item-title>
			</v-list-item-content>
			<v-list-item-action>
				<v-btn icon @click="notificationsDrawer = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-list-item-action>
		</v-list-item>

		<v-divider></v-divider>

		<v-list nav>
			<v-list-item v-if="notifications.length < 1">
				<v-list-item-subtitle>You're all up to date.</v-list-item-subtitle>
			</v-list-item>
			<v-list-item v-for="(notification, i) in notifications" link>
				<v-list-item-content>
					<v-list-item-title>{{ notification.text }}</v-list-item-title>
					<v-list-item-subtitle>{{ notification.subtext }}</v-list-item-subtitle>
					<v-list-item-content>
						<v-row>
							<v-col cols="auto">
								<v-btn rounded color="accent" @click="accept(notification)">accept</v-btn>
							</v-col>
							<v-col cols="auto">
								<v-btn rounded color="dark" @click="decline(notification)">decline</v-btn>
							</v-col>
						</v-row>
					</v-list-item-content>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
import {sync} from "vuex-pathify";

export default {
	name: 'RNotificationsDrawer',
	computed: {
		notifications: sync('app/notifications'),
		notificationsDrawer: sync('app/notificationsDrawer'),
	},
	data: () => ({
		notificationsInterval: null
	}),
	methods: {
		getNotifications() {
			this.$api.notifications.index()
				.then(response => this.notifications = response.data)
				.catch(error => console.log(error))
		},
		accept(notification) {
			if (notification.type === 'share') {
				this.$api.shareRequest.accept(notification.id)
					.then(response => {
						console.log('share request accepted')
						this.$router.push({name: 'ShowMemory', params: {id: response.data.id}})
						this.notificationsDrawer = false
					})
					.catch(error => console.log(error))
					.finally(() => this.getNotifications())
			}
			if (notification.type === 'connection') {
				this.$api.friendRequest.accept(notification.id)
					.then(response => console.log('connection request accepted'))
					.catch(error => console.log(error))
					.finally(() => this.getNotifications())
			}
		},
		decline(notification) {
			if (notification.type === 'share') {
				this.$api.shareRequest.decline(notification.id)
					.then(response => {
						console.log('share request declined')
					})
					.catch(error => console.log(error))
					.finally(() => this.getNotifications())
			}
			if (notification.type === 'connection') {
				this.$api.friendRequest.decline(notification.id)
					.then(response => console.log('connection request declined'))
					.catch(error => console.log(error))
					.finally(() => this.getNotifications())
			}
		},
	}
}
</script>