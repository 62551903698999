<template>
	<v-img class="attachment_card primary" :src="thumb_url">
		<v-btn v-if="editable" small fab color="secondary" class="ma-3" @click="$emit('delete')">
			<v-icon>mdi-delete</v-icon>
		</v-btn>
<!--		<r-lightbox v-model="showLightbox" :url="url" type="image"></r-lightbox>-->
	</v-img>
</template>

<script>
export default {
	name: "RCardImage",
	props: {
		thumb_url: String,
		url: String,
		editable: Boolean,
	},
}
</script>