<template>
	<v-card class="fill-height attachment_card" >
		<v-btn v-if="editable" small fab color="secondary" class="ma-3" @click="$emit('delete')" style="position:absolute; z-index:100;">
			<v-icon>mdi-delete</v-icon>
		</v-btn>
		<video :src="url" controls style="width: 100%; height: 100%;" :poster="require('@/assets/video_icon.png')"></video>
	</v-card>
</template>

<script>
export default {
	name: "RCardVideo",
	props: {
		url: String,
		editable: Boolean,
	}
}
</script>