// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/vuex-pathify'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules,
	plugins: [
		pathify.plugin,
		createPersistedState({
			storage: {
				getItem: key => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: key => ls.remove(key)
			},
			paths:[
				'user',
				'app'
			]
		})
	],
})

export default store

export const ROOT_DISPATCH = Object.freeze({root: true})
