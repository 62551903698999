<template>
	<v-btn class="mr-4 mb-4  account_deletion_alert_btn" fab dark color="error" :to="{name: 'Account'}">
		<v-icon dark large>mdi-exclamation</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: "RAccountDeletionAlert"
}
</script>

<style scoped lang="scss">
.account_deletion_alert_btn {
	position: fixed;
	bottom: 0;
	right: 0;
	animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(183,28,28, 0.9);
	}
	70% {
		-webkit-box-shadow: 0 0 0 20px rgba(183,28,28, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(183,28,28, 0);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(183,28,28, 0.9);
		box-shadow: 0 0 0 0 rgba(183,28,28, 0.9);
	}
	70% {
		-moz-box-shadow: 0 0 0 20px rgba(183,28,28, 0);
		box-shadow: 0 0 0 20px rgba(183,28,28, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(183,28,28, 0);
		box-shadow: 0 0 0 0 rgba(183,28,28, 0);
	}
}
</style>