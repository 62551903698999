import {Glassfy} from "capacitor-plugin-glassfy" ;

// Pathify
import {make} from 'vuex-pathify'

// Data
const state = {
	offerings: []
}

const getters = {}

const mutations = {
	...make.mutations(state),
}

const actions = {
	...make.actions(state),


}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
