<template>
	<v-container fluid>
		<v-row>
			<v-col :cols="grid_view ? 6 : 12" :sm="grid_view ? 4 : 6" md="4" lg="3" v-for="(item, i) in years">
				<r-memory-tile
					:thumbnail="item.thumbnail"
					:title="item.year"
					title-class="text-h6"
					:to="{name: 'Year', params: {year: item.year}}"
					:icon="item.icon"
				></r-memory-tile>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import api from "@/services/api";
import _sample from 'lodash/sample'
import {sync} from "vuex-pathify";

export default {
	name: 'Home',
	metaInfo: {
		title: 'Home',
	},
	computed: {
		title: sync('app/title'),
		user: sync('user/user'),
		grid_view: sync('app/grid_view'),
	},
	mounted() {
		this.init()
	},
	watch:{
		user(){
			this.init()
		}
	},
	data: () => ({
		years: [],
	}),
	methods: {
		init(){
			this.getMemories()
			this.title = 'rewind'
		},
		getMemories() {
			this.appLoading = true
			this.$api.memories.index()
				.then(response => {
					this.years = response.data
				})
				.catch(error => console.log(error))
				.finally(() => this.appLoading = false)
		},
	}
}
</script>
