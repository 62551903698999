<template>
	<v-card class="pa-5 text-center" :color="color" :elevation="elevation">
		<v-card-title class="d-block text-h4 mb-5" v-if="!hideTitle">profile</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="4">
					<v-row>
						<v-col cols="12" class="d-flex justify-center">
							<v-avatar size="200" style="max-width:100%;">
								<v-img :src="getFeaturedImage()" class="mb-5" style="max-width: 500px;" :key="user.avatar"></v-img>
							</v-avatar>
						</v-col>
						<v-col cols="12" class="d-flex justify-center">
							<r-file-upload accept="image/*" v-model="avatar" label="avatar" icon="mdi-image"></r-file-upload>
						</v-col>
					</v-row>

				</v-col>
				<v-col cols="12" md="8">
					<v-form v-model="valid">
						<v-row>
							<v-col cols="12" lg="6">
								<v-text-field v-model="user.first_name" label="first name"></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field v-model="user.last_name" label="last name"></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field v-model="user.alias" label="alias (nickname)"></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" :nudge-left="menuNudgeLeft" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="prettyDate" label="date of birth" readonly v-bind="attrs" v-on="on" hint="we only use your date of birth to calculate how many years of memories to generate." persistent-hint persistent-placeholder></v-text-field>
									</template>
									<v-date-picker v-model="user.date_of_birth" @input="dateMenu = false" :max="getMaxEndDate" :width="datePickerWidth"></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" lg="6" v-if="!hideEmail">
								<v-text-field type="email" v-model="user.email" label="email"></v-text-field>
							</v-col>
							<v-col cols="12" class="text-start">
								<p>enter a name and email below to nominate a loved one to receive an exported (text) version of your timeline, leaving a lasting legacy</p>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field v-model="user.legacy_name" label="legacy name"></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field type="email" v-model="user.legacy_email" label="legacy email"></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-checkbox v-model="user.marketing_opt_in" label="receive news, updates and offers from rewind"></v-checkbox>
							</v-col>
						</v-row>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-row class="justify-space-between">
				<v-col>
					<v-btn v-if="!hidePasswordReset" color="accent" rounded text class="mr-10" :to="{name:'ForgotPassword'}">reset password</v-btn>
				</v-col>
				<v-col>
					<v-btn v-if="showSave" color="accent" rounded :loading="saving" @click="save">save</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";
import {toast} from "@/utils/helpers";

export default {
	name: "RProfile",
	props: {
		hideTitle: Boolean,
		hidePasswordReset: Boolean,
		hideEmail: Boolean,
		showSave: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: "secondary",
		},
		elevation: {
			type: Number,
			default: 0,
		}
	},
	computed: {
		user: sync('user/user'),
		prettyDate() {
			return this.user.date_of_birth ? new Date(this.user.date_of_birth).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')
		},
	},
	data: () => ({
		avatar: null,
		valid: false,
		formData: new FormData(),
		saving: false,
		dateMenu: false,
	}),
	methods: {
		save() {
			if (this.valid) {
				this.saving = true;
				if (this.user.first_name != null) {
					this.formData.append("user[first_name]", this.user.first_name);
				}
				if (this.user.last_name != null) {
					this.formData.append("user[last_name]", this.user.last_name);
				}
				if (this.user.alias != null) {
					this.formData.append("user[alias]", this.user.alias);
				}
				if (this.user.email != null) {
					this.formData.append("user[email]", this.user.email);
				}
				if (this.user.legacy_name != null) {
					this.formData.append("user[legacy_name]", this.user.legacy_name);
				}
				if (this.user.legacy_email != null) {
					this.formData.append("user[legacy_email]", this.user.legacy_email);
				}
				if (this.user.date_of_birth != null) {
					this.formData.append("user[date_of_birth]", this.user.date_of_birth);
				}
				if (this.user.marketing_opt_in != null) {
					this.formData.append("user[marketing_opt_in]", this.user.marketing_opt_in);
				}
				if (this.avatar != null) {
					this.formData.append("user[avatar]", this.avatar);
				}
				this.$api.users.update(this.formData)
					.then(response => {
						this.user = response.data;
						this.$toast.add('profile updated', 'success')
					})
					.catch(error => {
						toast.handleResponseErrors(error)
						console.log(error)
					})
					.finally(() => this.saving = false)
			}
		},
		getFeaturedImage() {
			return this.avatar == null ? this.user.avatar : this.getImagePreview(this.avatar)
		},
	},

}
</script>