<template>
	<v-card elevation="0" color="transparent">
		<v-card-title class="pl-0" v-if="$is_native">access your memories in your web browser</v-card-title>
		<v-card-title class="pl-0" v-else>access your memories on your mobile</v-card-title>
		<v-row>
			<template v-if="$is_native">
				<v-col cols="12" md="6">
					<v-card dark color="primary">
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="text-h5">available on the web</v-card-title>
								<v-card-actions>
									<v-btn color="secondary" rounded href="https://app2.rewind-remind.me" target="_blank">web</v-btn>
								</v-card-actions>
							</div>

							<v-avatar class="ma-3 d-none d-sm-block" size="105">
								<v-icon size="100">mdi-web</v-icon>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="12" md="6">
					<v-card dark color="primary">
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="text-h5">available on android</v-card-title>
								<v-card-actions>
									<v-btn color="secondary" rounded href="https://play.google.com/store/apps/details?id=com.rewind.app" target="_blank">play store</v-btn>
								</v-card-actions>
							</div>
							<v-avatar class="ma-3 d-none d-sm-block" size="105">
								<v-icon size="100">mdi-android</v-icon>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" md="6">
					<v-card dark color="primary">
						<div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="text-h5">available on apple</v-card-title>
								<v-card-actions>
									<v-btn color="secondary" rounded href="https://apps.apple.com/gb/app/rewind-app/id1569393350" target="_blank">app store</v-btn>
								</v-card-actions>
							</div>
							<v-avatar class="ma-3 d-none d-sm-block" size="105">
								<v-icon size="100">mdi-apple</v-icon>
							</v-avatar>
						</div>
					</v-card>
				</v-col>
			</template>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: "RPlatformLinks"
}
</script>
