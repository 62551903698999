<template>
	<v-card class="mx-auto" tile color="secondary">
		<v-row align="center" justify="center">
			<v-col align-self="center" class="pa-0 mx-auto" cols="auto">
				<v-avatar class="profile ma-5" size="100">
					<v-img :src="imageSrc(null, true)"></v-img>
				</v-avatar>
			</v-col>
			<v-col class="py-0" cols="auto">
				<v-list-item color="rgba(0, 0, 0, .4)" dark>
					<v-list-item-content>
						<v-list-item-title class="text-h6">
							{{ name }}
						</v-list-item-title>
						<v-list-item-subtitle>{{ shareCount }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: "RUserTile",
	props: {
		to: {
			type: Object,
			required: true
		},
		name: {
			required: true,
		},
		shares: {
			required: true,
		},
		thumbnail: {
			type: String
		},
	},
	computed: {
		shareCount() {
			let w = this.shares > 1 ? 'memories' : 'memory'
			return `${this.shares} ${w} shared`
		}
	}
}
</script>

<style scoped>

</style>