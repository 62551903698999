<template>
	<v-card class="pa-5 mt-5 text-center" color="secondary">
		<v-card-title class="mb-5 d-block text-h4">curated memories</v-card-title>
		<v-card-subtitle class="text-center">rewind has added a selection of news stories, global events, popular film and music from your lifetime.</v-card-subtitle>
		<v-card-subtitle class="text-center">you can choose to include these in your timeline, by turning this option on/off, in account settings.</v-card-subtitle>
		<v-card-text>
			<v-row justify="center">
				<v-col cols="auto">
					<v-switch color="accent" v-model="user.include_curated" label="include curated memories" @change="save"></v-switch>
				</v-col>
			</v-row>
		</v-card-text>
		<v-img class="mx-auto" src="../assets/curated_example.png" max-width="300px"></v-img>
		<v-card-text>curated memories have the rewind logo on their thumbnail, so you can easily distinguish them from your own.</v-card-text>
		<v-card-text>
		</v-card-text>
	</v-card>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RCurated",
	computed: {
		user: sync('user/user')
	},
	methods: {
		save() {
			this.loading = true
			let formData = new FormData()
			formData.append('user[include_curated]', this.user.include_curated)
			this.$api.users.update(formData)
				.then(response => this.$emit('save', true))
				.catch(error => this.$toast.error(error))
				.finally(() => this.loading = false)
		}
	}
}
</script>

<style scoped>

</style>