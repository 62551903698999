// Pathify
import {make} from 'vuex-pathify'
import {toast} from '@/utils/helpers';
import api from '@/services/api'

// Data
const state = {
	user: {},
	token: '',
}

const getters = {
	...make.getters(state),
	userSignedIn: (state) => !!state.token,
	iapCheckRequired: (state) => state.user.iap_check_required
}

const mutations = make.mutations(state)

const actions = {
	...make.actions(state),
	getUser({commit}) {
		api.users.show()
			.then(response => this.user = response.data)
			.catch(error => console.log(error))
	},
	logout({commit}) {
		commit('user', {})
		commit('token', null)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
